import React, { useEffect } from "react";
import { Tooltip } from "react-tooltip";
import styled from "styled-components";
import {
  FlexColumn,
  StyledLabelText,
  StyledH4,
  StyledBodyText,
  FlexRow,
  StyledH0,
  StyledBodyTextSmall,
  Digits,
} from "../../styles";
import Icon from "../common/Icon";
import { formatValueToShort, outcomeDataMapper } from "./utils";

const Container = styled(FlexColumn)<{ type: string }>`
  width: ${(props) => (props.type === "large" ? "376px" : "243px")};
  height: 320px;
  border-radius: 16px;
  background: ${(props) =>
    props.type === "large"
      ? "linear-gradient(184.52deg, #a9c1d5 -69.37%, #d9e8fb 96.33%)"
      : "#f7f7f7"};
  padding: ${(props) =>
    props.type === "large" ? "8px 8px 16px" : "48px 8px 16px"};
`;

const IconContainer = styled(FlexRow)`
  align-content: center;
  justify-content: center;
`;

const IconContainerLarge = styled(FlexColumn)`
  justify-content: center;
  align-content: center;
`;

const CardHeader = styled(FlexRow)`
  align-content: start;
  height: 10%;
  min-height: 30px;
  margin-bottom: 8px;
`;

const VerifiedEstimatedContainer = styled(FlexRow)`
  min-width: 100%;
  padding-left: 28px;
  padding-right: 28px;
  justify-content: space-between;
`;

const VerifiedEstimatedContainerLarge = styled(FlexRow)`
  min-width: 100%;
  padding-left: 16px;
  padding-right: 16px;
  justify-content: space-between;
`;

const VerifiedContainer = styled(FlexColumn)`
  padding-left: 8px;
  border-left: 2px solid #14cd8d;
`;

const EstimatedContainer = styled(FlexColumn)`
  padding-left: 8px;
  border-left: 2px solid #05291d;
  align-self: end;
`;

const Volume = styled(Digits)`
  padding-top: 8px;
`;

const VolumeLarge = styled(StyledH0)``;

const TopImpactTagContainer = styled(FlexRow)`
  align-items: center;
  justify-content: center;
  background-color: #ffffff;
  border-radius: 18px;
`;

const TopImpactText = styled(StyledBodyTextSmall)`
  padding: 6px 16px;
`;

const VolumeDetailed = styled(StyledBodyText)`
  color: #5a5a5a;
`;

const Description = styled(StyledBodyText)`
  padding-bottom: 16px;
  color: #5a5a5a;
`;

const DescriptionLarge = styled(StyledBodyText)``;

const CardContent = styled(FlexColumn)`
  align-items: center;
  justify-content: center;
  height: 90%;
`;

const DataContainer = styled(FlexColumn)`
  height: 60%;
  align-items: center;
  justify-content: center;
`;

const DataContainerLarge = styled(FlexColumn)`
  align-items: center;
  justify-content: end;
`;

const StyledTooltip = styled(Tooltip)`
  && {
    background-color: white;
    color: black;
    border-radius: 16px;
    font-size: 12px;
    width: 200px;
`;

function TopImpactTag() {
  return (
    <TopImpactTagContainer>
      <TopImpactText>Top Impact</TopImpactText>
    </TopImpactTagContainer>
  );
}

type Props = {
  volume: number;
  unit: string;
  verified: number;
  estimated: number;
  type: "small" | "large";
  isSingleImpact: boolean;
  isVerifiedEstimatedFeatureEnabled: boolean;
};

function ImpactCard({
  volume,
  unit,
  verified,
  estimated,
  type,
  isSingleImpact,
  isVerifiedEstimatedFeatureEnabled,
}: Props) {
  const [cardData, setCardData] = React.useState(outcomeDataMapper(unit));

  useEffect(() => {
    setCardData(outcomeDataMapper(unit));
  }, [unit, type]);

  if (type === "large") {
    return (
      <Container type={type} data-testid={`impact-card-${type}-${unit}`}>
        <CardHeader>{!isSingleImpact && <TopImpactTag />}</CardHeader>
        <CardContent>
          <IconContainerLarge>
            <Icon
              size="large"
              icon={cardData?.icon}
              description={cardData?.unit}
            />
          </IconContainerLarge>
          <DataContainerLarge>
            <VolumeLarge>
              {volume > 999999
                ? `${formatValueToShort(volume)}*`
                : volume.toLocaleString()}
            </VolumeLarge>
            {volume > 999999 && (
              <VolumeDetailed>{`*${volume.toLocaleString()}`}</VolumeDetailed>
            )}
            <DescriptionLarge data-tooltip-id={`impact-${unit}`}>
              {cardData?.description}
            </DescriptionLarge>
            {isVerifiedEstimatedFeatureEnabled && (
              <VerifiedEstimatedContainerLarge>
                <VerifiedContainer>
                  <StyledLabelText>Verified</StyledLabelText>
                  <StyledH4>{`${verified}%`}</StyledH4>
                </VerifiedContainer>
                <EstimatedContainer>
                  <StyledLabelText>Estimated</StyledLabelText>
                  <StyledH4>{`${estimated}%`}</StyledH4>
                </EstimatedContainer>
              </VerifiedEstimatedContainerLarge>
            )}
          </DataContainerLarge>
        </CardContent>
        {unit === "Biodiversity" && (
          <StyledTooltip
            id={`impact-${unit}`}
            place="top"
            content="Earthly's voluntary biodiversity credits (VBC) each represent a 3x3m area of land within a wider project where actions are being taken to conserve and restore biodiversity."
            opacity={1}
          />
        )}
      </Container>
    );
  }

  return (
    <Container type={type} data-testid={`impact-card-${type}-${unit}`}>
      <IconContainer>
        <Icon icon={cardData?.icon} description={cardData?.unit} />
      </IconContainer>
      <DataContainer>
        <Volume>{volume.toLocaleString()}</Volume>
        <Description data-tooltip-id={`impact-${unit}`}>
          {cardData?.description}
        </Description>
        {isVerifiedEstimatedFeatureEnabled && (
          <VerifiedEstimatedContainer>
            <VerifiedContainer>
              <StyledLabelText>Verified</StyledLabelText>
              <StyledH4>{`${verified}%`}</StyledH4>
            </VerifiedContainer>
            <EstimatedContainer>
              <StyledLabelText>Estimated</StyledLabelText>
              <StyledH4>{`${estimated}%`}</StyledH4>
            </EstimatedContainer>
          </VerifiedEstimatedContainer>
        )}
      </DataContainer>
      {unit === "Biodiversity" && (
        <StyledTooltip
          id={`impact-${unit}`}
          place="top"
          content="Earthly's voluntary biodiversity credits (VBC) each represent a 3x3m area of land within a wider project where actions are being taken to conserve and restore biodiversity."
          opacity={1}
        />
      )}
    </Container>
  );
}

export default ImpactCard;
